import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import { Section, InnerMedium } from '../components/Sections'
import color from '../constants/colors'

const Heading = styled.h2`
  color: ${color.main};
`

const InnerStyled = styled(InnerMedium)`
  padding: 100px 60px;
`

const ThankYouPage = ({ location: { pathname } }) => (
  <Layout>
    <SEO
      title="お支払い完了　| ITエンジニア専用のオンライン英会話スクール・Moba Pro IT English（モバプロ）"
      description="支払い完了ページ"
      pathName={pathname}
      noFollowNoIndex
    />
    <Section>
      <InnerStyled>
        <Heading>お申し込み・お支払いありがとうございます</Heading>
        <p>体験レッスンのお申込みとお支払いが完了しました。</p>
        <p>通常3営業日以内にご入力頂いたメールアドレス宛にご返信いたします。</p>
      </InnerStyled>
    </Section>
  </Layout>
)

ThankYouPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
}

ThankYouPage.defaultProps = {
  location: null,
}

export default ThankYouPage
